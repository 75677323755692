#cyan {
  display: flex;
  min-width: $cyan-min-width;
  min-height: 100vh;
  position: relative;
  background-color: $content-bg;
  @include transition(filter $cyan-transition-duration);

  //
  // #content
  //

  #content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $content-bg;
    color: $content-color;

    .content-wrapper {
      margin-top: ($header-height-sm * 2);
      height: calc(100vh - #{$header-height-sm * 2});
      overflow: hidden;
      overflow-y: auto;

      .card {
        box-shadow: 0 .063rem .188rem rgba(0,0,0, .25);

        .card-header .card-title {
          font-size: $font-size-lg;
        }

        .card-block-header .card-title {
          font-size: $font-size-base;
        }

        .card-footer {
          .buttons {
            display: flex;
            width: 100%;

            .btn {
              width: 100%;
            }
          }
        }
      }

      form {
        .card:last-child {
          margin-bottom: 0;
        }
      }
    }

    .tooltip {
      z-index: $zindex-sidebar - 20;
    }
  }

  //
  // #toasts
  //

  #toasts {
    @extend .toast-container;
    z-index: $zindex-toasts;
  }

  //--------------------------------------------------
  // non-auth
  //--------------------------------------------------

  &.non-auth {
    background: $auth-bg;
    background-size: cover;

    #content {
      background-color: transparent;
      justify-content: center;
      align-items: center;

      .content-wrapper {
        margin-top: 0;
        padding: 0;
        width: 100%;
        max-width: 25rem;
        height: 100%;
        overflow: auto;
      }
    }

    form {
      height: 100%;
    }

    .card {
      min-height: 31.25rem;
      height: 100%;
      border-width: 0;
      background-color: transparent;
      box-shadow: none !important;

      .card-header,
      .card-block,
      .card-footer {
        color: $auth-color;
        text-shadow: .063rem .063rem rgba(invert($auth-color), .75);
        background-color: transparent;
        border-color: transparent;

        .input-group-addon .mdi,
        .notification {
          text-shadow: none;
        }
      }

      .card-header {
        height: calc(50% - #{$auth-card-footer-height});

        .brand {
          flex-direction: column;
          justify-content: center;
          font-size: 2.25rem;

          img {
            margin: 0 0 1rem;
            max-width: 6.5rem;
          }
        }

        &.locked {
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: $spacer-y;

          .avatar {
            display: block;
            margin-bottom: 1rem;

            .img {
              box-shadow: 0 0 .625rem rgba(#000, .65);
              border: .188rem solid #d4d4d4;
            }
          }
        }
      }

      .card-block {
        display: flex;
        flex-direction: column;
        padding: 0;
        height: 50%;

        .form-group {
          margin: 0;
          width: 100%;

          .input-group {
            .input-group-addon {
              border-width: 0;
              border-right: .063rem solid #d4d4d4;

              &.reminder {
                padding: 0;
                border: 0;
                position: absolute;
                right: 0;
                z-index: 3;
                height: 100%;
                background-color: transparent;

                .btn {
                  height: 100%;
                }
              }
            }

            .form-control {
              border: 0;
              background-color: $auth-input-bg;
              border-bottom: .063rem solid lighten(#c8cacb, 13%);
              box-shadow: none;
            }
          }

          &:nth-last-child(2) {
            .input-group {
              .form-control {
                border-bottom: 0;
              }
            }
          }
        }

        .btn {
          padding: ($btn-padding-y + .25rem) $btn-padding-x;
          margin: 0;
        }
      }

      .card-footer {
        height: $auth-card-footer-height;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        text-align: center;
        opacity: .65;
      }

      .auth-divider {
        margin: ($spacer-x + $form-group-margin-bottom) auto $spacer-x auto;
        height: .063rem;
        width: 3.125rem;
        background-color: $auth-color;
      }
    }
  }

  //--------------------------------------------------
  // Breakpoints
  //--------------------------------------------------

  //
  // $crm-mobile-breakpoint
  //

  @include media-breakpoint-up($cyan-mobile-breakpoint) {

    //
    // #content
    //

    #content {
      .content-wrapper {
        margin-top: $header-height;
        padding: ($content-padding-y / 2) ($content-padding-x / 2);
        height: calc(100vh - #{$header-height});

        .card {
          .card-header .card-title { font-size: $font-size-xl; }
          .card-block-header .card-title { font-size: $font-size-base; }
          .card-footer .buttons { width: auto; }
        }
      }
    }

    &.non-auth {
      #content {
        .content-wrapper { width: 60vw; }
      }

      .card {
        .card-header .brand img { max-width: 8.5rem; }
        .form-group {
          .input-group {
            margin-bottom: $form-group-margin-bottom;

            .form-control {
              @include box-shadow($input-box-shadow);
            }
          }
        }

      }
    }
  }

  //
  // xl-up
  //

  @include media-breakpoint-up(xl) {

    #content {
      .content-wrapper {
        padding: $content-padding-y $content-padding-x;
      }
    }

    &.non-auth {
      #content {
        .content-wrapper {
          padding: 0;
          min-width: 25rem;
          width: 100%;
          max-width: 26vh;
        }
      }

      .card {
        .card-block {
          .form-group {
            margin-bottom: $form-group-margin-bottom;

            .input-group {
              .form-control {
                background-color: rgba($auth-input-bg, .95);
                box-shadow: inset 0 0 1rem rgba(0,0,0, .25);
                border-bottom: 0;

                @include focus {
                  box-shadow: none;
                }
              }
            }
          }

          .auth-divider { display: none; }
        }
      }
    }
  }
}