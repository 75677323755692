.header-search {
  display: flex;
  padding: 0;
  background-color: $header-search-bg-sm;
  color: $header-search-color-sm;
  width: 100%;
  height: $header-height-sm;

  .input-group {
    position: static;
    align-items: center;

    .form-control {
      padding: (($header-height-sm - $input-line-height) / 2) 0;
      background-color: transparent;
      color: $header-search-input-color-sm;
      border: 0;
      box-shadow: none;

      @include placeholder {
        color: $header-placeholder-search-input-color-sm;
      }

      @include focus {
        color: $header-focus-search-input-color-sm;
      }
    }

    .input-group-addon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: transparent;
      border: 0;

      &.location {
        margin-right: ($spacer-x / 2);
        padding: $tag-padding-y $tag-padding-x;
        height: strip-unit($font-size-sm) + (strip-unit($tag-padding-y) * 2) + .125rem;
        border: .063rem solid $header-placeholder-search-input-color-sm;
        font-size: $font-size-sm;
        color: $header-placeholder-search-input-color-sm;

        .tag {
          padding: 0;
          background-color: transparent;
          color: inherit;
          font-size: inherit;
          font-weight: inherit;
        }
      }

      &.close {
        cursor: pointer;
        text-shadow: none;
        font-size: 1.25rem;
        width: $header-height-sm;
        color: $header-focus-search-input-color-sm;
        opacity: .5;

        &:hover {
          opacity: 1;
        }
      }
    }

    @include focus {
      .input-group-addon {
        &.location {
          background-color: $brand-primary;
          border-color: $brand-primary;
          color: $header-bg;
        }
      }
    }
  }
}

//--------------------------------------------------
// Breakpoints
//--------------------------------------------------

//
// $crm-mobile-breakpoint
//

@include media-breakpoint-up($cyan-mobile-breakpoint) {
  .header-search {
    background-color: $header-search-bg;
    color: $header-search-color;
    height: $header-height;

    .input-group {
      .form-control {
        padding: (($header-height - $input-line-height) / 2) 0;
        color: $header-search-input-color;

        @include placeholder {
          color: $header-placeholder-search-input-color;
        }

        @include focus {
          color: $header-focus-search-input-color;
        }
      }

      .input-group-addon {
        &.location {
          border-color: $header-placeholder-search-input-color;
          color: rgba($header-color, .5);
        }

        &.close {
          width: 2.563rem;
          color: $header-focus-search-input-color;
        }
      }

      @include focus {
        .input-group-addon {
          &.location {
            background-color: $brand-primary;
            border-color: $brand-primary;
            color: $header-bg;
          }
        }
      }
    }
  }
}

//
// $sidebar-breakpoint
//

@include media-breakpoint-up($sidebar-breakpoint) {
  .header-search {
    .input-group {
      flex-direction: row;

      .input-group-addon {
        &.location {
          margin-left: ($spacer-x / 2);
        }
      }
    }
  }
}
