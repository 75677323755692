.avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;

    img {
      max-width: 8rem;
    }

    .mdi.mdi-account-circle {
      font-size: 6rem;
    }

    &.icon {
      border: 0 !important;
      box-shadow: none !important;
    }
  }
}

.avatar-buttons {
  margin-top: $form-group-margin-bottom;
  display: flex;
  flex-direction: column;
  width: 100%;

  .mdi {
    font-size: $font-size-base;
  }
}

img.avatar {
  display: inline-block;
  width: auto;
  height: 2.813rem;
  margin-right: ($spacer-x / 2);
  border-radius: 50%;
}