@media print {
  body {
    overflow: auto !important;
  }

  #cyan {
    min-height: auto !important;
    padding: 0 !important;
    background-color: transparent !important;
  }

  .sidebar-wrapper,
  #header {
    display: none !important;
  }

  #content {
    background-color: transparent !important;

    .content-wrapper {
      padding: 0 !important;
      margin: 0 !important;
      height: auto !important;
    }
  }

  #toasts {
    display: none !important;
  }

  .card {
    border: 0 !important;
    box-shadow: none !important;

    .card-header,
    .card-footer {
      display: none !important;
    }
  }
}