.nprogress-container {
  pointer-events: none;

  .bar {
    width: 100%;
    height: 0.125rem;
    background: $brand-primary;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $zindex-loader - 1;
  }

  .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 6.25rem;
    height: 100%;
    box-shadow: 0 0 .625rem $brand-primary, 0 0 .313rem $brand-primary;
    opacity: 1;
    transform: rotate(3deg) translate(0, -.25rem);
  }
}