// loader

body {
  > .vs-loader-wrapper {
    position: fixed;
    background-color: $content-bg;
    z-index: $zindex-loader;
  }
}

[v-cloak] {
  display: none;
}

// brand

.brand {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: inherit !important;
  text-decoration: none !important;
}