//
// common
//

$cyan-min-width: 20rem !default;
$cyan-mobile-breakpoint: sm !default;
$cyan-transition-selector: all !default;
$cyan-transition-duration: .35s !default;
$cyan-transition: $cyan-transition-selector $cyan-transition-duration !default;

$zindex-loader:  9999 !default;
$zindex-toasts:  $zindex-modal + 10 !default;
$zindex-sidebar: $zindex-navbar !default;
$zindex-header:  $zindex-navbar - 5 !default;

//
// sidebar
//

$sidebar-header-padding-y: 0.625rem !default;
$sidebar-header-padding-x: 0.938rem !default;
$sidebar-header-bg: rgba(#fff,.05) !default;
$sidebar-header-color: #f1f3f6 !default;

$sidebar-breakpoint: md !default;
$sidebar-width: 14.375rem !default;
$sidebar-bg: #263238 !default;
$sidebar-color: #6d868c !default;
$sidebar-hover-color: #fff !default;

// sidebar-section
$sidebar-section-title-color: #fff !default;
$sidebar-section-title-font-size: 0.688em !default;
$sidebar-section-title-font-weight: 700 !default;

// sidebar-tem
$sidebar-item-padding-y: 0.5rem !default;
$sidebar-item-padding-x: 0.938rem !default;
$sidebar-item-bg: transparent !default;
$sidebar-item-color: rgba(#fff, .65) !default;
$sidebar-item-border-width: 0.188rem !default;
$sidebar-item-border-color: transparent !default;
$sidebar-item-font-size: $font-size-base !default;
$sidebar-item-font-weight: 300 !default;

$sidebar-hover-item-bg: transparent !default;
$sidebar-hover-item-color: #fff !default;
$sidebar-hover-item-border-color: transparent !default;

$sidebar-active-item-bg: rgba(#000, .15) !default;
$sidebar-active-item-color: #fff !default;
$sidebar-active-item-border-color: $brand-primary !default;

$sidebar-disabled-item-bg: transparent !default;
$sidebar-disabled-item-color: rgba(#fff, .3) !default;
$sidebar-disabled-item-border-color: transparent !default;

// sidebar submenu
$sidebar-submenu-item-padding-y: 0.188rem !default;
$sidebar-submenu-item-padding-x: 0.938rem !default;
$sidebar-submenu-item-bg: transparent !default;
$sidebar-submenu-item-color: #6d868c !default;

// sidebar submenu-item
$sidebar-submenu-item-border-width: 0.063rem !default;
$sidebar-submenu-item-border-color: rgba(#fff,.1) !default;
$sidebar-submenu-item-font-size: 0.875rem !default;
$sidebar-submenu-item-font-weight: 400 !default;
$sidebar-submenu-arrow-color: darken($sidebar-color, 10%) !default;

$sidebar-hover-submenu-item-bg: transparent !default;
$sidebar-hover-submenu-item-color: #fff !default;
$sidebar-hover-submenu-item-border-color: rgba(#fff,.1) !default;

$sidebar-active-submenu-item-bg: transparent !default;
$sidebar-active-submenu-item-color: $brand-primary !default;
$sidebar-active-submenu-item-border-color: rgba(#fff,.1) !default;

$sidebar-disabled-submenu-item-bg: transparent !default;
$sidebar-disabled-submenu-item-color: rgba(#6d868c, .5) !default;
$sidebar-disabled-submenu-item-border-color: rgba(#fff,.1) !default;

// sidebar-footer
$sidebar-footer-height: 2.5rem !default;

//
// header
//

$header-height: 3.750rem !default;
$header-height-sm: 3.125rem !default;
$header-bg: #fff !default;
$header-color: $body-color !default;

$header-search-bg: transparent !default;
$header-search-color: $header-color !default;
$header-search-bg-sm: rgba($sidebar-bg,.95) !default;
$header-search-color-sm: $sidebar-color !default;
$header-search-input-color: rgba($header-color, .5) !default;
$header-focus-search-input-color: $header-color !default;
$header-placeholder-search-input-color: rgba($header-color, .35) !default;
$header-search-input-color-sm: $sidebar-color !default;
$header-focus-search-input-color-sm: $sidebar-hover-color !default;
$header-placeholder-search-input-color-sm: $sidebar-color !default;

$header-nav-item-bg: transparent !default;
$header-nav-item-color: rgba(#748885, .65) !default;
$header-hover-nav-item-bg: transparent !default;
$header-hover-nav-item-color: #748885 !default;
$header-active-nav-item-bg: transparent !default;
$header-active-nav-item-color: $brand-primary !default;

$header-nav-dropdown-bg: #fff !default;
$header-nav-dropdown-color: inherit !default;

$header-nav-dropdown-bg-sm: #ffffff !default;
$header-nav-dropdown-color-sm: inherit !default;

$header-nav-dropdown-header-height: 2.5rem !default;
$header-nav-dropdown-header-bg: #303c41 !default;
$header-nav-dropdown-header-color: #fff !default;

$header-nav-dropdown-header-bg-sm: #f9f9f9 !default;
$header-nav-dropdown-header-color-sm: inherit !default;

$header-nav-dropdown-item-bg: transparent !default;
$header-nav-dropdown-item-color: $dropdown-link-color !default;

$header-hover-nav-dropdown-item-bg: $dropdown-link-hover-bg !default;
$header-hover-nav-dropdown-item-color: $dropdown-link-hover-color !default;

//
// content
//

$content-bg: #f1f3f6 !default;
$content-color: $body-color !default;
$content-padding-y: 1.875rem !default;
$content-padding-x: 1.875rem !default;

//
// auth
//

$auth-bg: url(/cyan/img/bg/auth.jpg) no-repeat center center fixed !default;
$auth-color: #ffffff !default;
$auth-card-footer-height: 3.125rem !default;
$auth-input-bg: $auth-color !default;
