@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,700|Roboto:300,400,700,900&subset=latin-ext);

// Options
$enable-rounded: false;

// Links
$link-hover-decoration: none;

// Body
$body-color: #373a3c;

// Fonts
$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-monospace: 'Roboto Mono', monospace;