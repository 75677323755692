#cyan {
  #sidebar {
    @extend .non-selectable;
    position: fixed;
    display: flex;
    width: $sidebar-width;
    left: -$sidebar-width;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    min-height: 100vh;
    height: 100vh;
    z-index: $zindex-sidebar;
    background: $sidebar-bg;
    color: $sidebar-color;
    box-shadow: .063rem 0 .188rem rgba(0,0,0,.25);
    @include transition($cyan-transition);

    .sidebar-header {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      z-index: 1;
      min-height: $header-height-sm;
      max-height: $header-height-sm;
      background-color: $sidebar-header-bg;
      color: $sidebar-header-color;
      font-weight: 300;

      .nav-item {
        padding: 0;
        margin: 0;
        min-width: $header-height-sm;
        height: 100%;

        .nav-link {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $sidebar-submenu-item-color;
          cursor: pointer;

          @include hover-focus {
            color: $sidebar-hover-submenu-item-color;
          }

          @include active {
            color: $sidebar-active-submenu-item-color;
          }
        }
      }

      .brand {
        cursor: pointer;
      }

      img {
        display: none;
        margin-right: $sidebar-header-padding-y;
      }
    }

    .sidebar-group {
      @extend .list-group;
      position: relative;
      padding: ($sidebar-item-padding-y * 2) 0;
      width: $sidebar-width;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      overflow-x: hidden;
      overflow-y: auto;
      @include box-shadow(inset 0 -.313rem .313rem -.313rem rgba(0, 0, 0, .5), inset 0 .313rem .313rem -.313rem rgba(0, 0, 0, .25));
    }

    .sidebar-item-group {
      .sidebar-item-group-title {
        color: $sidebar-section-title-color;
        font-size: $sidebar-section-title-font-size;
        font-weight: $sidebar-section-title-font-weight;
        padding: ($sidebar-item-padding-y * 2) $sidebar-item-padding-x $sidebar-item-padding-y;
        letter-spacing: .1em;
        text-transform: uppercase;
      }
    }

    .sidebar-item {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      min-height: $line-height-base + ($sidebar-item-padding-y * 2);
      border-left: $sidebar-item-border-width solid $sidebar-item-border-color;
      padding: $sidebar-item-padding-y $sidebar-item-padding-x;
      cursor: pointer;
      background-color: $sidebar-item-bg;
      color: $sidebar-item-color;
      font-size: $sidebar-item-font-size;
      font-weight: $sidebar-item-font-weight;

      .sidebar-item-icon {
        @include md-icon;
        @include md-icon-fw;
        position: relative;
        margin-right: 0.625rem;
        color: $sidebar-item-color;
        height: 1rem;
        opacity: .65;

        img {
          vertical-align: top;
          width: (18em / 14);
          height: 100%;
          opacity: .65;
        }
      }

      .sidebar-item-title {
        width: 100%;
        @include text-truncate;
      }

      @include hover {
        background-color: $sidebar-hover-item-bg;
        border-color: $sidebar-hover-item-border-color;
        color: $sidebar-hover-item-color;

        > .sidebar-item-icon {
          color: $sidebar-hover-item-color;
          opacity: 1;

          img {
            opacity: 1;
          }
        }
      }

      @include active {
        background-color: $sidebar-active-item-bg;
        border-color: $sidebar-active-item-border-color;
        color: $sidebar-active-item-color;

        > .sidebar-item-icon {
          color: $sidebar-active-item-color;
          opacity: 1;

          img {
            opacity: 1;
          }
        }
      }

      &.disabled {
        background-color: $sidebar-disabled-item-bg !important;
        border-color: $sidebar-disabled-item-border-color !important;
        color: $sidebar-disabled-item-color !important;
        cursor: $cursor-disabled;

        .sidebar-item-icon {
          opacity: .3 !important;
          color: $sidebar-disabled-item-color !important;
        }
      }
    }

    .sidebar-submenu {
      > .sidebar-item {
        padding: $sidebar-item-padding-y $sidebar-item-padding-x;

        &::after {
          color: $sidebar-submenu-arrow-color;
          content: $md-var-chevron-left;
          @include md-icon;
          @include md-icon-fw;
          @include transition($cyan-transition);
        }
      }

      > .submenu {
        padding-left: $sidebar-item-padding-x + ((1.2857142857rem - $sidebar-submenu-item-border-width) / 2);
        overflow: hidden;

        &.closing,
        &.closed {
          max-height: 0;
          transition: max-height .15s linear;
        }

        &.opening,
        &.opened {
          max-height: 15.625rem;
          transition: max-height .15s linear;
          transition-delay: .07s;
        }

        .sidebar-item-group {
          .sidebar-item-group-title {
            padding: ($sidebar-item-padding-y * 2) $sidebar-item-padding-x ($sidebar-item-padding-y * 2) 0;
          }
        }

        .sidebar-item {
          min-height: $line-height-base + ($sidebar-submenu-item-padding-y * 2);
          border-left: $sidebar-submenu-item-border-width solid $sidebar-submenu-item-border-color;
          margin: 0;
          padding: $sidebar-submenu-item-padding-y $sidebar-submenu-item-padding-x $sidebar-submenu-item-padding-y ($sidebar-submenu-item-padding-x + (0.625rem / 2));
          background-color: $sidebar-submenu-item-bg;
          color: $sidebar-submenu-item-color;
          font-size: $sidebar-submenu-item-font-size;
          font-weight: $sidebar-submenu-item-font-weight;

          @include hover {
            background-color: $sidebar-hover-submenu-item-bg;
            border-color: $sidebar-hover-submenu-item-border-color;
            color: $sidebar-hover-submenu-item-color;

            .sidebar-item-icon {
              color: $sidebar-hover-submenu-item-color;
            }
          }

          @include active {
            background-color: $sidebar-active-submenu-item-bg;
            border-color: $sidebar-active-submenu-item-border-color;
            color: $sidebar-active-submenu-item-color;

            .sidebar-item-icon {
              color: $sidebar-active-submenu-item-color;
            }
          }

          &.disabled {
            background-color: $sidebar-disabled-submenu-item-bg !important;
            border-color: $sidebar-disabled-submenu-item-border-color !important;
            color: $sidebar-disabled-submenu-item-color !important;
            cursor: $cursor-disabled;

            .sidebar-item-icon {
              color: $sidebar-disabled-submenu-item-color !important;
            }
          }
        }
      }

      &.opened {
        > .sidebar-item::after {
          transform: rotate(-90deg);
        }
      }
    }

    .sidebar-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: ($spacer-x / 2);
      width: 100%;
      min-height: $sidebar-footer-height;
      background-color: rgba(0, 0, 0, .15);
      cursor: default;

      .tag {
        display: block;
        background-color: transparent;
        color: rgba($sidebar-color, .75);
        font-size: $font-size-sm;
        font-weight: 300;

        &.build_date {
          font-size: $font-size-xs;
        }
      }
    }

    .vs-loader-wrapper {
      background-color: $sidebar-bg;
    }
  }

  //
  // sidebar-overlay
  //

  .sidebar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($sidebar-bg, .2);
    display: none;
    z-index: $zindex-sidebar - 1;
  }

  //
  // sidebar-opened
  //

  &.sidebar-opened {
    #sidebar {
      left: 0;
    }

    .sidebar-overlay {
      display: block;
    }
  }
}

//--------------------------------------------------
// Breakpoints
//--------------------------------------------------

//
// $crm-mobile-breakpoint
//

@include media-breakpoint-up($cyan-mobile-breakpoint) {
  #cyan {
    #sidebar {
      .sidebar-header {
        min-height: $header-height;
        max-height: $header-height;

        .nav-item {
          min-width: $header-height;
        }
      }
    }
  }
}

//
// $sidebar-breakpoint
//

@include media-breakpoint-up($sidebar-breakpoint) {
  #cyan {
    &:not(.non-auth) {
      padding-left: $sidebar-width;
    }

    #sidebar {
      left: 0;

      .sidebar-header {
        padding: $sidebar-header-padding-y $sidebar-header-padding-x;

        img {
          display: block;
          height: $header-height - ($sidebar-header-padding-y * 2);
        }
      }
    }

    .sidebar-overlay {
      display: none !important;
    }
  }
}
