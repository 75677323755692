#header {
  @extend .non-selectable;
  position: fixed;
  min-width: $cyan-min-width;
  width: 100vw;
  min-height: $header-height-sm;
  background-color: $header-bg;
  color: $header-color;
  border-bottom: .063rem solid rgba(#000, .15);
  z-index: $zindex-header;

  .nav-item {
    width: 100%;

    .nav-link {
      width: 100%;
      height: $header-height-sm;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $header-nav-item-bg;
      color: $header-nav-item-color;
      cursor: pointer;

      &::after {
        content: none;
      }

      @include hover-focus {
        background-color: $header-hover-nav-item-bg;
        color: $header-hover-nav-item-color;
      }

      @include active {
        background-color: $header-active-nav-item-bg;
        color: $header-active-nav-item-color;
      }
    }

    &.dropdown.open {
      .nav-link {
        background-color: $header-active-nav-item-bg;
        color: $header-active-nav-item-color;
      }
    }
  }

  .sidebar-toggle {
    max-width: $header-height-sm;

    .nav-link {
      background-color: $header-search-bg-sm;
      color: $header-search-color-sm;

      @include hover-focus {
        background-color: $header-search-bg-sm;
        color: $header-focus-search-input-color-sm;
      }
    }
  }

  .nav-header {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    flex-basis: 100%;

    .nav-item.dropdown { position: static; }

    .dropdown-menu {
      padding: 0;
      width: 100vw;
      height: calc(100vh - (#{$header-height-sm} * 2));
      margin-top: 0;
      left: 0;
      right: auto;
      border-width: 0;
      background-color: $header-nav-dropdown-bg-sm;
      color: $header-nav-dropdown-color-sm;
      z-index: $zindex-dropdown - 2;

      .dropdown-header {
        display: flex;
        margin: 0;
        padding: 0;
        min-height: $header-nav-dropdown-header-height;
        background-color: $header-nav-dropdown-header-bg-sm;
        color: $header-nav-dropdown-header-color-sm;
        border-top: $card-border-width solid $card-border-color;
        border-bottom: $card-border-width solid $card-border-color;
        font-size: $font-size-lg;

        .title {
          padding: $card-spacer-y $card-spacer-x;
          align-self: stretch;
          align-items: center;
          display: flex;
        }

        .actions {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-basis: 100%;
          font-size: $font-size-xs;

          .btn {
            opacity: .5;

            @include hover-focus {
              opacity: 1;
            }
          }
        }
      }

      .dropdown-content {
        align-self: stretch;
        overflow: auto;
        height: calc(100% - #{$header-nav-dropdown-header-height});

        .empty {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: rgba(0,0,0, .4);
          padding: 1rem 0;
          font-weight: 300;
          font-size: $font-size-h5;
        }
      }

      .dropdown-item {
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
      }
    }

    .header-dropdown-item {
      display: flex;
      padding: ($dropdown-padding-y / 2);
      cursor: pointer;
      border-bottom: $dropdown-border-width solid $dropdown-border-color;
      background-color: $header-nav-dropdown-item-bg;
      color: $header-nav-dropdown-item-color;

      @include hover-focus {
        background-color: $header-hover-nav-dropdown-item-bg;
        color: $header-hover-nav-dropdown-item-color;
      }

      .header-dropdown-item-image {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        margin-right: .5rem;
      }

      .header-dropdown-item-data {
        display: flex;
        flex-direction: column;
        font-size: $font-size-sm;

        .author {
          font-weight: 700;
        }

        .time {
          font-size: .65rem;
          opacity: .65;
        }
      }
    }

    #header-notifications {
      .header-dropdown-item {
        .header-dropdown-item-image {
          width: 4.375rem - $dropdown-padding-y;
          background-color: $state-primary-bg;
          color: $state-primary-text;

          .mdi {
            padding: $spacer-x;
            width: auto;
          }
        }

        &.primary {
          .header-dropdown-item-image {
            background-color: $state-primary-bg;
            color: $state-primary-text;
          }
        }

        &.secondary {
          .header-dropdown-item-image {
            background-color: $state-secondary-bg;
            color: $state-secondary-text;
          }
        }

        &.info {
          .header-dropdown-item-image {
            background-color: $state-info-bg;
            color: $state-info-text;
          }
        }

        &.success {
          .header-dropdown-item-image {
            background-color: $state-success-bg;
            color: $state-success-text;
          }
        }

        &.warning {
          .header-dropdown-item-image {
            background-color: $state-warning-bg;
            color: $state-warning-text;
          }
        }

        &.danger {
          .header-dropdown-item-image {
            background-color: $state-danger-bg;
            color: $state-danger-text;
          }
        }
      }
    }

    #header-usernav {
      .avatar {
        .img {
          border: .125rem solid #d4d4d4;
          opacity: .65;
        }
      }

      .dropdown-item {
        padding: ($dropdown-item-padding-x * .75) $dropdown-item-padding-x;
      }

      &:hover {
        .avatar .img {
          opacity: 1;
        }
      }

      &.open {
        .avatar .img {
          border-color: $brand-primary;
          opacity: 1;
        }
      }
    }
  }
}

//--------------------------------------------------
// Breakpoints
//--------------------------------------------------

//
// $crm-mobile-breakpoint
//

@include media-breakpoint-up($cyan-mobile-breakpoint) {
  #header {
    min-height: $header-height;

    .nav-item {
      max-width: $header-height !important;

      .nav-link {
        min-width: $header-height;
        height: $header-height;
      }
    }

    .sidebar-toggle {
      .nav-link {
        background-color: $header-nav-item-bg;
        color: $header-nav-item-color;

        @include hover-focus {
          background-color: $header-hover-nav-item-bg;
          color: $header-hover-nav-item-color;
        }

        @include active {
          background-color: $header-active-nav-item-bg;
          color: $header-active-nav-item-color;
        }
      }
    }

    .nav-header {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 0;
      max-width: none;

      .dropdown-menu {
        width: 23.438rem;
        height: auto;
        max-height: calc(100vh - #{$header-height} - (0.313rem * 2));
        margin: 0.313rem 0.313rem 0 0;
        right: 0;
        left: auto;
        border-width: $dropdown-border-width;
        background-color: $header-nav-dropdown-bg;
        color: $header-nav-dropdown-color;
        box-shadow: 0 .063rem .188rem rgba(0, 0, 0, .25);
        z-index: $zindex-dropdown;

        .dropdown-header {
          background-color: $header-nav-dropdown-header-bg;
          color: $header-nav-dropdown-header-color;
          font-size: $font-size-base;

          .title { padding: $spacer-x / 1.25; }
          .actions .btn { padding: $btn-padding-y; }
        }

        .dropdown-content {
          height: 100%;
          max-height: 17rem;
        }
      }

      #header-usernav {
        .dropdown-menu {
          width: $sidebar-width;

          .dropdown-item {
            padding: $dropdown-item-padding-y $dropdown-item-padding-x;

            &.active {
              background-color: $component-active-bg;
              color: $component-active-color;
            }
          }
        }
      }
    }
  }
}

//
// $sidebar-breakpoint
//

@include media-breakpoint-up($sidebar-breakpoint) {
  #header {
    width: calc(100vw - #{$sidebar-width});

    .nav-header { justify-content: flex-end; }
  }
}
