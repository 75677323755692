.media {
  .payed {
    color: #fff;
  }

  .not-payed {
    color: $brand-warning;
  }
}

.table-danger,
.table-warning,
.table-info,
.table-success {
  .payed,
  .not-payed{
    color: #fff !important;
  }
}